import {FC, useEffect} from 'react';
import Select from '@semcore/ui/select';
import {ButtonTrigger, LinkTrigger} from '@semcore/ui/base-trigger';
import {Text} from '@semcore/ui/typography';
import Link from '@semcore/ui/link';
import {Flex} from '@semcore/ui/flex-box';
import Tooltip from '@semcore/ui/tooltip';

interface ISelect {
  value: string | string[];
  options: {value: string; label?: string; children: string}[];
  onChange: (value: string | string[]) => void;
  placeholer?: string;
  tag?: 'button' | 'link';
  multiselect?: boolean;
  disable?: number;
  disableTooltipText?: string;
}

const SelectData: FC<ISelect> = ({tag, multiselect, ...props}) => {
  const handleChangeValue = (newValue: string[]) => {
    const {value, options, onChange} = props;
    if (newValue.length && newValue.at(-1) === 'controlButton') {
      value[0] === 'all' ? onChange([]) : onChange(['all']);
    } else {
      onChange(newValue.length === options.length ? ['all'] : newValue);
    }
  };

  return !multiselect ? (
    <Select
      onChange={props.onChange}
      placeholder={props.placeholer}
      value={props.value}
    >
      <Select.Trigger tag={tag === 'link' ? LinkTrigger : ButtonTrigger}>
        <Select.Trigger.Text>
          {props.options.find((item) => item.value === props.value)?.label}
        </Select.Trigger.Text>
      </Select.Trigger>
      <Select.Popper>
        <Select.List>
          {props.options.map((option, index) =>
            index == props.disable ? (
              <Tooltip placement='right'>
                <Tooltip.Trigger w={'100%'}>
                  <Link w={'100%'}>
                    <Select.Option
                      value={option.value}
                      key={option.value}
                      disabled={index == props.disable}
                    >
                      {option.label}
                    </Select.Option>
                  </Link>
                </Tooltip.Trigger>
                <Tooltip.Popper>{props.disableTooltipText}</Tooltip.Popper>
              </Tooltip>
            ) : (
              <Select.Option
                value={option.value}
                key={option.value}
                disabled={index == props.disable}
              >
                {option.label}
              </Select.Option>
            )
          )}
        </Select.List>
      </Select.Popper>
    </Select>
  ) : (
    // <Select tag={tag === 'link' ? LinkTrigger : ButtonTrigger} {...props} />
    <Select
      placeholder={props.placeholer}
      multiselect
      onChange={(value) => handleChangeValue(value as string[])}
      value={
        props.value.length && props.value[0] === 'all'
          ? props.options.map((option) => option.value)
          : props.value
      }
    >
      <Select.Trigger tag={tag === 'link' ? LinkTrigger : ButtonTrigger}>
        <Select.Trigger.Text>
          {props.value.includes('all')
            ? 'All'
            : `${props.value.length} selected`}
        </Select.Trigger.Text>
      </Select.Trigger>
      <Select.Popper w={'100px'}>
        <Select.List>
          <Select.Option value={'controlButton'}>
            <Text color="denim-blue">
              {props.value.length && props.value[0] === 'all'
                ? 'Deselect all'
                : 'Select all'}
            </Text>
          </Select.Option>
          {props.options.map((option) => (
            <Select.Option value={option.value} key={option.value}>
              <Select.Option.Checkbox value={option.value} />
              {option.label}
            </Select.Option>
          ))}
        </Select.List>
      </Select.Popper>
    </Select>
  );
};

export default SelectData;
