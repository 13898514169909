import {FC, useEffect, useRef, useState} from 'react';
import {
  getKeywordsByName,
  getSubredditsByName,
  getTopKeywords,
  getTopSubreddits,
} from '../../api/request';
import store from '../../stores/store';
import DropdownMenu from '@semcore/dropdown-menu';
import {ButtonTrigger} from '@semcore/base-trigger';
import Input from '@semcore/input';
import SpinContainer from '@semcore/ui/spin-container';
import {Flex} from '@semcore/ui/flex-box';
import {Text} from '@semcore/ui/typography';
import { appStore } from '../../stores';

interface Props {
  search: string;
  type?: 'keywords' | 'subreddits';
  onChange: (search: string) => void;
  keyNumber: number;
  placeholder?: string;
  keyDownHandler?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const DropdownSearch: FC<Props> = ({
  search,
  onChange,
  type = 'subreddits',
  keyNumber,
  placeholder,
  keyDownHandler,
}) => {
  const [dropdownData, setDropdownData] = useState<string[]>([]);
  const timeoutCurrent = useRef<any>(null);
  const [searchValue, setSearchValue] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchValue(search);
  }, []);

  useEffect(() => {
    const params = {...store.globalFilters, isNew: 0};
    if (timeoutCurrent.current) clearTimeout(timeoutCurrent.current);
    timeoutCurrent.current = setTimeout(async () => {
      setLoading(true);
      if (type === 'subreddits') {
        if (!searchValue || searchValue === '') {
          getTopSubreddits(params)
            .then((json) => {
              const data = json.subreddit_data;
              setDropdownData(data.map((item) => item.subreddit));
            })
            .finally(() => setLoading(false))
            .catch((err) => console.log(err));
        } else {
          if (appStore.checkSearch(searchValue)) {
            setDropdownData([])
            setLoading(false)
            return;
          }
          getSubredditsByName({name: searchValue, ...params})
            .then((json) => {
              if (json.subreddit_data) {
                const data = json.subreddit_data;
                setDropdownData(data.map((item) => item.subreddit));
              } else {
                setDropdownData([]);
              }
            })
            .finally(() => setLoading(false))
            .catch((err) => console.log(err));
        }
      } else {
        if (!searchValue || searchValue === '') {
          getTopKeywords(params)
            .then((json) => {
              const data = json.keyword_data;
              if (data) {
                setDropdownData(data.map((item) => item.keyword));
              } else {
                setDropdownData([]);
              }
            })
            .finally(() => setLoading(false))
            .catch((err) => console.log(err));
        } else {
          if (appStore.checkSearch(searchValue)) {
            setDropdownData([])
            setLoading(false)
            return;
          }
          getKeywordsByName({name: searchValue, ...params})
            .then((json) => {
              if (json.keyword_data) {
                const data = json.keyword_data;
                setDropdownData(data.map((item) => item.keyword));
              } else {
                setDropdownData([]);
              }
            })
            .finally(() => setLoading(false))
            .catch((err) => console.log(err));
        }
      }
    }, 1000);
  }, [searchValue]);

  const handleChangeSearch = (newValue: string) => {
    onChange(newValue);
    setSearchValue(newValue);
    inputRef.current?.focus();
  };

  return (
    <DropdownMenu>
      <DropdownMenu.Trigger w={196} mr={2}>
        <Input size={'m'}>
          <Input.Value
            value={searchValue}
            onChange={handleChangeSearch}
            placeholder={placeholder ?? 'Search'}
            ref={inputRef}
            onKeyDown={keyDownHandler}
          />
        </Input>
      </DropdownMenu.Trigger>

      {/* <DropdownMenu.Popper tag={SpinContainer} loading={loading} hMax={200}> */}
      <DropdownMenu.Menu>
        <SpinContainer loading={loading} size="s">
          {dropdownData && dropdownData.length > 0 ? (
            dropdownData.map((item) => (
              <DropdownMenu.Item
                key={`compare-dropdown-menu-${item}-${keyNumber}`}
                onClick={() => {
                  handleChangeSearch(item);
                }}
              >
                {item}
              </DropdownMenu.Item>
            ))
          ) : (
            <Flex px={4} py={2}>
              <Text size={200}>Sorry, nothing matched your search</Text>
            </Flex>
          )}
        </SpinContainer>
      </DropdownMenu.Menu>
      {/* </DropdownMenu.Popper> */}
    </DropdownMenu>
  );
};

export default DropdownSearch;
