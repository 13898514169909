import {Box, Flex} from '@semcore/ui/flex-box';
import Link from '@semcore/ui/link';
import Button from '@semcore/ui/button';
import {Page} from '../../ui';
import {Text} from '@semcore/ui/typography';
import {useNavigate} from 'react-router-dom';
import PageError from '@semcore/illustration/PageError';
import {appStore} from '../../stores';

export const ErrorPage = () => {
  const navigation = useNavigate();

  const handleReloadClick = () => {
    navigation('/');
    window.location.reload();
  };

  return (
    <Page>
      <Flex id="heightControl" alignItems="center" justifyContent="center">
        <Flex>
          <PageError />
          <Flex
            justifyContent="center"
            direction="column"
            alignItems="start"
            ml={10}
          >
            <Text tag={'h1'}>Something went wrong</Text>
            <Text mt={4}>
              We are aware of the issue and are working to fix it.
            </Text>
            <Text>
              Please try again later or contact the{' '}
              <Link href="mailto:app-center@semrush.com">Support Team</Link>.
            </Text>
            {!appStore.loadError.tokenError && (
              <Box w={'min-content'} mt={6}>
                <div onClick={handleReloadClick}>
                  <Button use="primary" size="l">
                    Try again
                  </Button>
                </div>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Page>
  );
};
