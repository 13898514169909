import {FC, Fragment, useEffect, useMemo, useState} from 'react';
import DataTable, {DataTableSort} from '@semcore/ui/data-table';
import {Plot, minMax, Area} from '@semcore/ui/d3-chart';
import {scaleLinear} from 'd3-scale';
import {curveCardinal} from 'd3-shape';
import Pagination from '@semcore/ui/pagination';
import Link from '@semcore/ui/link';
import Badge from '@semcore/ui/badge';
import ArrowUpM from '@semcore/ui/icon/ArrowUp/m';
import ArrowDownM from '@semcore/ui/icon/ArrowDown/m';
import Tooltip from '@semcore/ui/tooltip';
import InfoM from '@semcore/ui/icon/Info/m';
import {
  KeywordsTableDataType,
  PostsTableDataType,
  SubredditsTableDataType,
  TopAdsType,
  TopClicksType,
  TopPostsType,
  VisitsTableDataType,
} from '../../types/entity';
import {Box, Flex} from '@semcore/ui/flex-box';
import {convertNumberToShortString} from '../../utils/helpers';
import {NoData} from '@semcore/ui/widget-empty';
import Skeleton from '@semcore/ui/skeleton';
import {appStore} from '../../stores';
import {observer} from 'mobx-react';
import moment from 'moment';
import {Text} from '@semcore/ui/typography';
import Divider from '@semcore/ui/divider';

export type TableType =
  | 'Keyword'
  | 'Subreddit'
  | 'Post'
  | 'topClicks'
  | 'topPosts';

const titles = {
  visits: [
    'Number Of Visits',
    'Unique Visitors',
    'Events Per Visitor',
    'Trend',
    'Change',
  ],
  topClicks: ['Top Clicks', 'Clicks'],
  topPosts: ['Top Posts', 'Clicks'],
};

const columns = {
  visits: [
    'num_visits',
    'num_unique_users',
    'events_per_visitor',
    'trend',
    'change',
  ],
  topClicks: ['topClicks', 'clicks'],
  topPosts: ['topPosts', 'clicks'],
};

const chartMargin = 12;
const chartWidth = 148;
const chartHeight = 45;

const itemsOnPage = 12;

interface ITable {
  data:
    | Array<SubredditsTableDataType>
    | Array<KeywordsTableDataType>
    | Array<PostsTableDataType>
    | Array<TopClicksType>
    | Array<TopAdsType>
    | Array<TopPostsType>;
  tableType: TableType;
  disablePagination?: boolean;
  title?: React.ReactNode;
  rowClickHandle?: (row: string) => any;
  dataTest?: string;
}

const sortableColumn = [
  'num_visits',
  'num_unique_users',
  'events_per_visitor',
  'clicks',
  'change',
  'search_volume',
];

const smallTableType = ['topClicks', 'topPosts'];

type SortableColumn = (typeof sortableColumn)[number];
type SmallTableType = (typeof smallTableType)[number];

const Table: FC<ITable> = ({
  data,
  tableType,
  rowClickHandle,
  disablePagination,
  title,
  dataTest,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sort, setSort] = useState<DataTableSort>(['num_visits', 'desc']);
  const sortedData = useMemo(
    () =>
      [...data].sort((aRow: any, bRow: any) => {
        const [prop, sortDirection] = sort;
        const a = aRow[prop as SortableColumn];
        const b = bRow[prop as SortableColumn];
        if (a === b) return 0;
        if (sortDirection === 'asc') return a - b;
        else return b - a;
      }),
    [sort, data]
  );

  useEffect(() => {
    if (data.length === 0) setCurrentPage(-1);
    else setCurrentPage(0);
  }, [data, sort]);

  const getSkeleton = (tableColumns: string[]) => {
    return tableColumns.map((c) => ({
      cssVar: `--${c}_width`,
      name: c,
      data: (
        <Skeleton height={'29px'}>
          <Skeleton.Text y="5" width="100%" />
        </Skeleton>
      ),
    }));
  };

  const getLoading = () => {
    return appStore.loading[
      tableType === 'Keyword'
        ? 'keywords'
        : tableType === 'topClicks'
        ? 'keywordsCompared'
        : tableType === 'Subreddit'
        ? 'subreddits'
        : tableType === 'topPosts'
        ? 'subredditsCompared'
        : 'posts'
    ];
  };

  const isSortable = (column: string): column is SortableColumn =>
    sortableColumn.includes(column);

  const isSmallTable = (type: string): type is SmallTableType =>
    smallTableType.includes(type);

  let tableColumns: Array<string> = [];
  let tableTitles: Array<string> = [];

  if (tableType === 'Subreddit' || tableType === 'Post') {
    tableColumns = [tableType.toLowerCase(), ...columns['visits']];
    tableTitles = [tableType, ...titles['visits']];
  } else if (tableType === 'Keyword') {
    tableColumns = [
      tableType.toLowerCase(),
      columns.visits[3],
      columns.visits[4],
      'search_volume',
    ];
    tableTitles = [
      tableType,
      titles.visits[3],
      titles.visits[4],
      'Search Volume',
    ];
  } else if (isSmallTable(tableType)) {
    tableColumns = columns[tableType];
    tableTitles = titles[tableType];
  }

  return (
    <div style={{marginTop: '12px'}} data-test={dataTest}>
      <div className="table-section">
        <DataTable
          data={sortedData.slice(
            currentPage * itemsOnPage,
            currentPage * itemsOnPage + itemsOnPage
          )}
          use={'secondary'}
          sort={sort}
          onSortChange={(sort) => setSort(sort)}
          style={{borderRadius: '6px', overflow: 'hidden'}}
        >
          <DataTable.Head
            h={45}
            style={{borderRadius: '6px 0'}}
            disabledScroll={title ? true : false}
            hMin={title ? 72 : 'auto'}
            wMin={isSmallTable(tableType) ? '1000px' : undefined}
          >
            {tableColumns.map((column, index) => (
              <DataTable.Column
                key={`table-${tableType}-column-${column}`}
                name={column}
                fixed={
                  isSmallTable(tableType) && index !== 0 ? 'right' : undefined
                }
                w={
                  isSmallTable(tableType) && index === 0
                    ? '85%'
                    : column === 'trend'
                    ? chartWidth
                    : undefined
                }
                wMin={index === 0 ? '40%' : undefined}
                wMax={column === 'change' ? '100px' : undefined}
                justifyContent={
                  isSortable(column)
                    ? 'end'
                    : column === 'change'
                    ? 'center'
                    : 'start'
                }
                alignItems={title && index !== 0 ? 'end' : 'center'}
                flex={index === 0 ? '1 0 auto' : ''}
                p={3}
                sortable={isSortable(column)}
              >
                {/* <Flex> */}
                {index === 0 ? (
                  <Flex
                    direction="column"
                    justifyContent="space-between"
                    h={title ? 72 : 'auto'}
                  >
                    {title && <Flex pt={3}>{title}</Flex>}
                    <Flex pb={title ? 3 : ''} alignItems="center">
                      {tableTitles[index]}
                      <Tooltip
                        interaction="hover"
                        placement="right"
                        theme="default"
                        title={
                          column === 'post'
                            ? "A Reddit 'post' is the broad term for any content shared on Reddit, whether it is a story, link, image, poll or video"
                            : column === 'subreddit'
                            ? 'Subreddits are sub-communities within Reddit, dedicated to a specific topic or theme'
                            : column === 'topClicks'
                            ? 'Top links users click after the selected search request'
                            : column === 'topPosts'
                            ? 'Top posts of this subreddit in terms of clicks'
                            : 'Keywords are words and phrases that users type into search field to find information on a particular topic'
                        }
                      >
                        <div style={{cursor: 'pointer'}}>
                          <Flex alignItems="center">
                            <InfoM color="#A9ABB6" ml={'4px'} />
                          </Flex>
                        </div>
                      </Tooltip>
                    </Flex>
                  </Flex>
                ) : (
                  <div
                    style={{cursor: isSortable(column) ? 'pointer' : 'auto'}}
                  >
                    {tableTitles[index]}
                  </div>
                )}
              </DataTable.Column>
            ))}
          </DataTable.Head>
          {data.length === 0 && !getLoading() && (
            <NoData
              type="table"
              pt={
                tableType === 'topPosts'
                  ? '38px'
                  : tableType === 'topClicks'
                  ? '28px'
                  : 49
              }
              pb={
                tableType === 'topPosts'
                  ? '39px'
                  : tableType === 'topClicks'
                  ? '29px'
                  : 49
              }
            />
          )}
          {getLoading() ? (
            <DataTable.Body
              {...{
                rows: Array(
                  tableType === 'topClicks' || tableType === 'topPosts' ? 5 : 12
                )
                  .fill(0)
                  .map(() => {
                    return getSkeleton(tableColumns);
                  }),
              }}
            />
          ) : (
            <DataTable.Body>
              <DataTable.Row<{}>>
                {(props, row, index) => {
                  return {
                    onClick: () => {
                      rowClickHandle?.(row[tableColumns[0]] as string);
                    },
                  };
                }}
              </DataTable.Row>
              <DataTable.Cell<{}>
                name="subreddit/keyword/post/topClicks/topAds/topPosts"
                h={45}
              >
                {(props, row) => {
                  return {
                    children: (
                      <Flex
                        alignItems="center"
                        // pl={'12px'}
                      >
                        <div
                          onClick={() => {
                            if (
                              props.name === 'topClicks' ||
                              props.name === 'topAds' ||
                              props.name === 'topPosts'
                            )
                              window.open(
                                row[props.name] as string,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            if (props.name == 'post')
                              window.open(
                                row['post_url'] as string,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            if (props.name === 'subreddit')
                              window.open(
                                row['subreddit_link'] as string,
                                '_blank',
                                'noopener,noreferrer'
                              );
                          }}
                        >
                          <Link>{row[props.name] as string}</Link>
                        </div>
                        {row['new'] === 1 && tableType !== 'Post' && (
                          <Badge bg="green-400" ml={'4px'}>
                            new
                          </Badge>
                        )}
                      </Flex>
                    ),
                  };
                }}
              </DataTable.Cell>
              <DataTable.Cell<{}> name={sortableColumn.join('/')} h={45}>
                {(props, row) => {
                  return {
                    children: (
                      <Flex alignItems="center">
                        {convertNumberToShortString(row[props.name] as number)}
                      </Flex>
                    ),
                  };
                }}
              </DataTable.Cell>
              <DataTable.Cell<{}> name="trend" h={45}>
                {(props, row) => {
                  return {
                    children: (
                      <Flex alignItems="center">
                        <Plot
                          data={row.trend}
                          scale={[
                            scaleLinear()
                              .range([0, chartWidth - chartMargin])
                              .domain(minMax(row.trend, 'x')),
                            scaleLinear()
                              .range([chartHeight - chartMargin, chartMargin])
                              .domain(minMax(row.trend, 'y')),
                          ]}
                          width={chartWidth}
                          height={chartHeight}
                        >
                          <Area x="x" y="y" curve={curveCardinal}>
                            <Area.Line style={{strokeWidth: 1}} />
                          </Area>
                        </Plot>
                      </Flex>
                    ),
                  };
                }}
              </DataTable.Cell>
              <DataTable.Cell<{}> name="change" h={45}>
                {(props, row) => {
                  const value = row[props.name] as number;
                  const currentMonthSum = (
                    row['trend'] as {x: number; y: number}[]
                  )
                    .slice(4, 8)
                    .reduce((sum, n) => sum + n.y, 0);
                  const previousMonthSum = (
                    row['trend'] as {x: number; y: number}[]
                  )
                    .slice(0, 4)
                    .reduce((sum, n) => sum + n.y, 0);
                  return {
                    children: (
                      <Tooltip placement="left">
                        <Tooltip.Trigger>
                          <div style={{cursor: 'pointer'}}>
                            <Flex alignItems="center">
                              {value > 0 ? (
                                <>
                                  <ArrowUpM />
                                  {value + '%'}
                                </>
                              ) : value < 0 ? (
                                <>
                                  <ArrowDownM />
                                  {value * -1 + '%'}
                                </>
                              ) : (
                                <> {value + '%'}</>
                              )}
                            </Flex>
                          </div>
                        </Tooltip.Trigger>
                        <Tooltip.Popper wMin={260}>
                          <Box>
                            <Flex justifyContent="space-between">
                              <div>{`Current (${moment(
                                appStore.globalFilters.month,
                                'YYYYMM'
                              ).format('MMM YYYY')})`}</div>
                              <Text bold>
                                {convertNumberToShortString(currentMonthSum)}
                              </Text>
                            </Flex>
                            <Flex justifyContent="space-between" mt={2}>
                              <div>{`Previous (${moment(
                                appStore.globalFilters.month,
                                'YYYYMM'
                              )
                                .subtract(1, 'month')
                                .format('MMM YYYY')})`}</div>
                              <Text bold>
                                {convertNumberToShortString(previousMonthSum)}
                              </Text>
                            </Flex>
                            <Divider mt={2} />
                            <Flex justifyContent="space-between" mt={2}>
                              <div>{`Difference`}</div>
                              <Text
                                style={{
                                  color:
                                    value > 0
                                      ? '#007C65'
                                      : value < 0
                                      ? '#D1002F'
                                      : '',
                                }}
                              >
                                {`${convertNumberToShortString(
                                  Math.abs(currentMonthSum - previousMonthSum)
                                )} (${value}%)`}
                              </Text>
                            </Flex>
                          </Box>
                        </Tooltip.Popper>
                      </Tooltip>
                    ),
                    style: {
                      color: value > 0 ? '#007C65' : value < 0 ? '#D1002F' : '',
                    },
                  };
                }}
              </DataTable.Cell>
              {!disablePagination && (
                <Flex
                  justifyContent="right"
                  mt={'12px'}
                  mr={'20px'}
                  pb={'16px'}
                  h={45}
                >
                  <Pagination
                    currentPage={currentPage + 1}
                    onCurrentPageChange={(page) => setCurrentPage(page - 1)}
                    totalPages={Math.ceil(data.length / itemsOnPage)}
                  />
                </Flex>
              )}
            </DataTable.Body>
          )}
        </DataTable>
      </div>
    </div>
  );
};

export default observer(Table);
