import React, {Component} from 'react';
import {Flex, Box} from '@semcore/ui/flex-box';
import Input from '@semcore/ui/input';
import Button from '@semcore/ui/button';
import Link from '@semcore/link';
import MathPlusXS from '@semcore/icon/lib/MathPlus/xs';
import CloseXS from '@semcore/icon/lib/Close/xs';
import Select from '@semcore/ui/select';
// import {PromptItemType} from '../pages/retailKeywordsTab/RetailKeywordsTab';
import {gaussRound, roundThousand} from '../utils/helpers';

interface IProps {
  showAditionalyInputs: boolean;
  // keywords: Array<{keyword: string; value: any; prompt: Array<PromptItemType>}>;
  keywords: any;
  onClickShowAditionalyInputs: () => void;
  onClickCloseAditionalyInputs: () => void;
  onChangeKeyword: (value: string, index: number) => void;
  onClickCompare: () => void;
  emptyInputs: boolean;
  onClearInput: (key: number) => void;
}

class InputsKeywords extends Component<IProps> {
  render() {
    const {
      showAditionalyInputs,
      keywords,
      onClickShowAditionalyInputs,
      onClickCloseAditionalyInputs,
      onChangeKeyword,
      onClickCompare,
      onClearInput,
      emptyInputs,
    } = this.props;
    return (
      <Box m={'12px 0px 0 0px'}>
        <Select
          state={emptyInputs ? 'invalid' : 'normal'}
          interaction="focus"
          onChange={(value: string) => onChangeKeyword(value, 0)}
          value={keywords[0].keyword}
        >
          <Select.Trigger
            size={'m'}
            w={'140px'}
            m={'0 8px 0 0'}
            pl={0}
            tag={Input}
          >
            <Select.Trigger.Text wMin={'100%'}>
              <Flex alignItems={'center'}>
                <Input.Value
                  placeholder="Input keyword"
                  onChange={(value: string) => onChangeKeyword(value, 0)}
                  value={keywords[0].keyword}
                  pr={'0px'}
                  onKeyUp={(event: any) => {
                    if (event.key === 'Enter') onClickCompare();
                  }}
                />
                <Input.Addon
                  tag={CloseXS}
                  interactive
                  onClick={() => onClearInput(0)}
                />
              </Flex>
            </Select.Trigger.Text>
          </Select.Trigger>
          {keywords[0].prompt.length > 0 && (
            <Select.Menu>
              {/* {keywords[0].prompt.map((value: PromptItemType, idx: number) => {
                return (
                  <Select.Option value={value.keyword} key={idx}>
                    <Flex
                      alignItems={'center'}
                      justifyContent="space-between"
                      w={'100%'}
                    >
                      <Box style={{fontSize: 16}}>{value.keyword}</Box>
                      <Box>
                        <Flex alignItems={'center'} justifyContent="flex-end">
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Clicks: ${roundThousand(
                            value.product_clicks
                          )}`}</Box>
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Search Requests: ${roundThousand(
                            value.unique_searches
                          )}`}</Box>
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Orders: ${roundThousand(value.orders)}`}</Box>
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Conversions: ${gaussRound(
                            (+value.orders / +value.product_clicks) * 100,
                            2
                          )}%`}</Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Select.Option>
                );
                // return < Select.Option value={item} key={index + item.keyword}>
                //     {item}
                // </Select.Option>
              })} */}
            </Select.Menu>
          )}
        </Select>

        <Select
          // state={emptyInputs ? 'invalid' : 'normal'}
          interaction="focus"
          onChange={(value: string) => onChangeKeyword(value, 1)}
          value={keywords[1].keyword}
        >
          <Select.Trigger
            size={'m'}
            w={'140px'}
            m={'0 8px 0 0'}
            pl={0}
            tag={Input}
          >
            <Select.Trigger.Text wMin={'100%'}>
              <Flex alignItems={'center'}>
                <Input.Value
                  placeholder="Input keyword"
                  onChange={(value: string) => onChangeKeyword(value, 1)}
                  value={keywords[1].keyword}
                  pr={'0px'}
                  onKeyUp={(event: any) => {
                    if (event.key === 'Enter') onClickCompare();
                  }}
                />
                <Input.Addon
                  tag={CloseXS}
                  interactive
                  onClick={() => onClearInput(1)}
                />
              </Flex>
            </Select.Trigger.Text>
          </Select.Trigger>
          {keywords[1].prompt.length > 0 && (
            <Select.Menu>
              {/* {keywords[1].prompt.map((value: PromptItemType, idx: number) => {
                return (
                  <Select.Option value={value.keyword} key={idx}>
                    <Flex
                      alignItems={'center'}
                      justifyContent="space-between"
                      w={'100%'}
                    >
                      <Box style={{fontSize: 16}}>{value.keyword}</Box>
                      <Box>
                        <Flex alignItems={'center'} justifyContent="flex-end">
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Clicks: ${roundThousand(
                            value.product_clicks
                          )}`}</Box>
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Search Requests: ${roundThousand(
                            value.unique_searches
                          )}`}</Box>
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Orders: ${roundThousand(value.orders)}`}</Box>
                          <Box
                            mr={2}
                            style={{fontSize: 12, color: '#757575'}}
                          >{`Conversions: ${gaussRound(
                            (+value.orders / +value.product_clicks) * 100,
                            2
                          )}%`}</Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Select.Option>
                );
              })} */}
            </Select.Menu>
          )}
        </Select>

        {showAditionalyInputs && (
          <React.Fragment>
            <Select
              // state={emptyInputs ? 'invalid' : 'normal'}
              interaction="focus"
              onChange={(value: string) => onChangeKeyword(value, 2)}
              value={keywords[2].keyword}
            >
              <Select.Trigger
                size={'m'}
                w={'140px'}
                m={'0 8px 0 0'}
                pl={0}
                tag={Input}
              >
                <Select.Trigger.Text wMin={'100%'}>
                  <Flex alignItems={'center'}>
                    <Input.Value
                      placeholder="Input keyword"
                      onChange={(value: string) => onChangeKeyword(value, 2)}
                      value={keywords[2].keyword}
                      pr={'0px'}
                      onKeyUp={(event: any) => {
                        if (event.key === 'Enter') onClickCompare();
                      }}
                    />
                    <Input.Addon
                      tag={CloseXS}
                      interactive
                      onClick={() => onClearInput(2)}
                    />
                  </Flex>
                </Select.Trigger.Text>
              </Select.Trigger>
              {keywords[2].prompt.length > 0 && (
                <Select.Menu>
                  {/* {keywords[2].prompt.map(
                    (value: PromptItemType, idx: number) => {
                      return (
                        <Select.Option value={value.keyword} key={idx}>
                          <Flex
                            alignItems={'center'}
                            justifyContent="space-between"
                            w={'100%'}
                          >
                            <Box style={{fontSize: 16}}>{value.keyword}</Box>
                            <Box>
                              <Flex
                                alignItems={'center'}
                                justifyContent="flex-end"
                              >
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Clicks: ${roundThousand(
                                  value.product_clicks
                                )}`}</Box>
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Search Requests: ${roundThousand(
                                  value.unique_searches
                                )}`}</Box>
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Orders: ${roundThousand(
                                  value.orders
                                )}`}</Box>
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Conversions: ${gaussRound(
                                  (+value.orders / +value.product_clicks) * 100,
                                  2
                                )}%`}</Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Select.Option>
                      );
                    }
                  )} */}
                </Select.Menu>
              )}
            </Select>
            <Select
              // state={emptyInputs ? 'invalid' : 'normal'}
              interaction="focus"
              onChange={(value: string) => onChangeKeyword(value, 3)}
              value={keywords[3].keyword}
            >
              <Select.Trigger
                size={'m'}
                w={'140px'}
                m={'0 8px 0 0'}
                pl={0}
                tag={Input}
              >
                <Select.Trigger.Text wMin={'100%'}>
                  <Flex alignItems={'center'}>
                    <Input.Value
                      placeholder="Input keyword"
                      onChange={(value: string) => onChangeKeyword(value, 3)}
                      value={keywords[3].keyword}
                      pr={'0px'}
                      onKeyUp={(event: any) => {
                        if (event.key === 'Enter') onClickCompare();
                      }}
                    />
                    <Input.Addon
                      tag={CloseXS}
                      interactive
                      onClick={() => onClearInput(3)}
                    />
                  </Flex>
                </Select.Trigger.Text>
              </Select.Trigger>
              {/* {keywords[3].prompt.length > 0 && (
                <Select.Menu>
                  {keywords[3].prompt.map(
                    (value: PromptItemType, idx: number) => {
                      return (
                        <Select.Option value={value.keyword} key={idx}>
                          <Flex
                            alignItems={'center'}
                            justifyContent="space-between"
                            w={'100%'}
                          >
                            <Box style={{fontSize: 16}}>{value.keyword}</Box>
                            <Box>
                              <Flex
                                alignItems={'center'}
                                justifyContent="flex-end"
                              >
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Clicks: ${roundThousand(
                                  value.product_clicks
                                )}`}</Box>
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Search Requests: ${roundThousand(
                                  value.unique_searches
                                )}`}</Box>
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Orders: ${roundThousand(
                                  value.orders
                                )}`}</Box>
                                <Box
                                  mr={2}
                                  style={{fontSize: 12, color: '#757575'}}
                                >{`Conversions: ${gaussRound(
                                  (+value.orders / +value.product_clicks) * 100,
                                  2
                                )}%`}</Box>
                              </Flex>
                            </Box>
                          </Flex>
                        </Select.Option>
                      );
                    }
                  )}
                </Select.Menu>
              )} */}
            </Select>
          </React.Fragment>
        )}
        <Button
          onClick={onClickCompare}
          use={'primary'}
          theme={'success'}
          size={'m'}
        >
          <Button.Text>{'Compare'}</Button.Text>
        </Button>
        {showAditionalyInputs && (
          <Button
            ml={2}
            onClick={onClickCloseAditionalyInputs}
            size="m"
            theme="muted"
            use="secondary"
          >
            <Button.Text>{'Cancel'}</Button.Text>
          </Button>
        )}
        {!showAditionalyInputs && (
          <Button
            onClick={onClickShowAditionalyInputs}
            ml={4}
            size="m"
            theme="info"
            use="tertiary"
          >
            <Button.Addon>
              <MathPlusXS width={10} height={10} color="#0071BC" />
            </Button.Addon>
            <Button.Text>{' Add up to 2 different keywords'}</Button.Text>
          </Button>
        )}
      </Box>
    );
  }
}

export default InputsKeywords;
