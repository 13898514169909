import {observable, action} from 'mobx';
import moment from 'moment';
import {
  GlobalFiltersParams,
  getKeywordTrend,
  getKeywordsByName,
  getSubredditTrend,
  getSubredditsByName,
  getTopKeywords,
  getTopPosts,
  getTopSubreddits,
} from '../api/request';
import {AvailableCountriesList} from '../types/enums';
import {
  ComparedTrendsType,
  KeywordsTableDataType,
  PostsTableDataType,
  SubredditsTableDataType,
  TrendPeriodType,
  ComparedKeywordsTrendsType,
  isNewType,
  ComparedSubredditTrendsType,
  SearchStringType,
  LoadingMarkType,
} from '../types/entity';
import {loadavg} from 'os';

export type TabType = 'keywords' | 'subreddits' | 'posts' | 'compare';

class appStore {
  @observable globalFilters: GlobalFiltersParams = {
    month:
      +moment().format('D') >= 5
        ? moment().subtract(1, 'month').format('YYYYMM')
        : moment().subtract(2, 'month').format('YYYYMM'),
    devices: 'all',
    gender: 'all',
    age: ['all'],
    location: 'all',
  };
  @observable isNew: isNewType = {
    keywords: 0,
    posts: 0,
    subreddits: 0,
  };
  @observable period: TrendPeriodType = 'm1';
  @observable selectedTab: TabType = 'subreddits';
  @observable subreddits: Array<SubredditsTableDataType> = [];
  @observable posts: Array<PostsTableDataType> = [];
  @observable keywords: Array<KeywordsTableDataType> = [];
  @observable searchString: SearchStringType = {
    keywords: '',
    subreddits: '',
  };
  @observable comparedKeywords: string[] = ['', ''];
  @observable comparedKeywordsTrends: ComparedKeywordsTrendsType[] = [];
  @observable comparedSubreddits: string[] = Array(4).map(() => '');
  @observable comparedSubredditsTrends: ComparedSubredditTrendsType[] = [];
  @observable mainKeyword: string = '';
  @observable mainSubreddit: string = '';
  @observable loading: LoadingMarkType = {
    keywords: false,
    keywordsCompared: false,
    subreddits: false,
    subredditsCompared: false,
    posts: false,
  };
  @observable loadError = {error: false, tokenError: false};

  @action setLoadError = (value: {error: boolean; tokenError: boolean}) => {
    this.loadError = value;
  };

  @action setGlobalFilters = (filters: GlobalFiltersParams) => {
    this.globalFilters = filters;
  };

  @action setSelectedTab = (value: TabType) => {
    // if (value === 'compare' && this.compareType === 'keyword') {
    //   this.comparedKeywords = this.comparedKeywords.concat(['', '']);
    // } else if (value === 'keywords' && this.comparedKeywords.length === 4) {
    //   this.comparedKeywords = this.comparedKeywords.slice(0, 2);
    // }
    this.selectedTab = value;
  };

  @action setIsNew = (value: 0 | 1) => {
    if (this.selectedTab !== 'compare') {
      this.isNew[this.selectedTab] = value;
    }
  };

  @action setTrendPeriod = (value: TrendPeriodType) => {
    this.period = value;
  };

  @action setSearchString = (search: string, key: keyof SearchStringType) => {
    this.searchString[key] = search;
  };

  @action setComparedKeywords = (value: string[]) => {
    this.comparedKeywords = value;
  };

  @action setComparedSubreddits = (value: string[]) => {
    this.comparedSubreddits = value;
  };

  @action setMainKeyword = (value: string) => {
    this.mainKeyword = value;
    this.comparedKeywords = [value].concat(
      this.comparedKeywords.filter((_, index) => index !== 0)
    );
  };

  @action setMainSubreddit = (value: string) => {
    this.mainSubreddit = value;
    this.comparedSubreddits = [value].concat(
      this.comparedSubreddits.slice(1, 4)
    );
  };

  @action setLoading = (key: keyof LoadingMarkType, value: boolean) => {
    this.loading[key] = value;
  };

  convertTrend = (trend: number[]) => {
    if (trend.length < 2) {
      trend.splice(0, 0, 0);
    }
    return trend.map((number, i) => ({x: i, y: number}));
  };

  prepareCompareTrend = (trend: number[], period: TrendPeriodType) => {
    const length = period == 'm1' ? 4 : period == 'm3' ? 12 : 24;
    trend = trend.reverse();
    let compareTrend = trend;
    if (trend.length < length)
      compareTrend = [...trend, ...Array(length - trend.length).fill(0)];

    compareTrend = compareTrend.reverse();
    return compareTrend;
  };

  checkSearch = (search: string) => {
    let check = false;
    search.split('').map((char) => {
      if (char === '.') check = true;
    });
    return check;
  };

  @action getSubreddits = () => {
    this.setLoading('subreddits', true);
    if (this.searchString.subreddits === '') {
      getTopSubreddits({...this.globalFilters, isNew: this.isNew.subreddits})
        .then((json) => {
          const data = json.subreddit_data;
          if (data) {
            this.subreddits = data.map((subreddit) => ({
              ...subreddit,
              trend: subreddit.trend ? this.convertTrend(subreddit.trend) : [],
            }));
            this.setMainSubreddit(this.subreddits[0].subreddit);
          } else {
            this.subreddits = [];
            this.setMainSubreddit('');
          }
        })
        .then(() => {
          this.getComparedSubredditsTrends();
        })
        .finally(() => this.setLoading('subreddits', false))
        .catch((err) => {});
    } else {
      if (this.checkSearch(this.searchString.subreddits)) {
        this.subreddits = [];
        this.setMainSubreddit('');
        this.setLoading('subreddits', false);
        return;
      }
      getSubredditsByName({
        ...this.globalFilters,
        isNew: this.isNew.subreddits,
        name: this.searchString.subreddits,
      })
        .then((json) => {
          if (json.subreddit_data) {
            const data = json.subreddit_data;
            this.subreddits = data.map((subreddit) => ({
              ...subreddit,
              trend: subreddit.trend ? this.convertTrend(subreddit.trend) : [],
            }));
            this.setMainSubreddit(this.subreddits[0].subreddit);
            this.getComparedSubredditsTrends();
          } else {
            this.subreddits = [];
            this.setMainSubreddit('');
          }
        })
        .finally(() => this.setLoading('subreddits', false))
        .catch((err) => {});
    }
  };

  @action getKeywords = () => {
    this.setLoading('keywords', true);
    if (this.searchString.keywords === '') {
      getTopKeywords({...this.globalFilters, isNew: this.isNew.keywords})
        .then((json) => {
          if (json.keyword_data) {
            const data = json.keyword_data;
            this.keywords = data.map((keyword) => ({
              ...keyword,
              trend: keyword.trend ? this.convertTrend(keyword.trend) : [],
            }));
            this.setMainKeyword(this.keywords[0].keyword);
          } else {
            this.keywords = [];
            this.setMainKeyword('');
          }
        })
        .then(() => {
          this.getComparedKeywordsTrends();
        })
        .finally(() => this.setLoading('keywords', false))
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (this.checkSearch(this.searchString.keywords)) {
        this.keywords = [];
        this.setMainKeyword('');
        this.setLoading('keywords', false);
        return;
      }
      getKeywordsByName({
        ...this.globalFilters,
        isNew: this.isNew.keywords,
        name: this.searchString.keywords,
      })
        .then((json) => {
          if (json.keyword_data) {
            const data = json.keyword_data;
            this.keywords = data.map((keyword) => ({
              ...keyword,
              trend: keyword.trend ? this.convertTrend(keyword.trend) : [],
            }));
            this.setMainKeyword(this.keywords[0].keyword);
            this.getComparedKeywordsTrends();
          } else {
            this.keywords = [];
            this.setMainKeyword(this.searchString.keywords);
          }
        })
        .finally(() => this.setLoading('keywords', false))
        .catch((err) => {});
    }
  };

  @action getComparedKeywordsTrends = () => {
    this.setLoading('keywordsCompared', true);
    const period = this.period;
    const comparedKeywords = this.comparedKeywords.filter((str) => str !== '');

    if (comparedKeywords.length == 0) {
      this.comparedKeywordsTrends = [];
      this.setLoading('keywordsCompared', false);
      return;
    }

    let tempTrends: ComparedKeywordsTrendsType[] = [];
    for (let i = 0; i < comparedKeywords.length; i++) {
      getKeywordTrend({
        ...this.globalFilters,
        name: comparedKeywords[i],
        period: period,
      })
        .then((json) => {
          if (json.keyword_data) {
            const data = json.keyword_data[0];
            tempTrends[i] = {
              keyword: data.keyword,
              trend: this.prepareCompareTrend(data.trend, period),
              topClicks: data.top_link.map((item) => {
                const splitedItem = item.split('->');
                return {
                  topClicks: splitedItem[0],
                  clicks: +splitedItem[1],
                };
              }),
              topAds: data.top_ads.map((item) => {
                const splitedItem = item.split('->');
                return {
                  topAds: splitedItem[0],
                  clicks: +splitedItem[1],
                };
              }),
            };
          } else {
            tempTrends[i] = {
              keyword: this.comparedKeywords[i],
              trend: Array(period === 'm1' ? 4 : period === 'm3' ? 3 : 6).fill(
                0
              ),
              topClicks: [],
              topAds: [],
            };
          }
        })
        .then(() => {
          if (tempTrends.length === comparedKeywords.length) {
            this.comparedKeywordsTrends = tempTrends;
          } else {
            this.comparedKeywordsTrends = [];
          }
        })
        .finally(() => this.setLoading('keywordsCompared', false))
        .catch((err) => {
          console.log(err);
        });
    }
  };

  @action getComparedSubredditsTrends = () => {
    this.setLoading('subredditsCompared', true);
    const period = this.period;
    let offset = 0;
    this.mainSubreddit =
      this.comparedSubreddits.find((item) => item && item !== '') ??
      this.mainSubreddit;
    const comparedSubreddits = this.comparedSubreddits;

    if (!comparedSubreddits.find((item) => item !== '')) {
      this.comparedSubredditsTrends = [];
      this.setLoading('subredditsCompared', false);
      return;
    }

    let tempTrends: ComparedSubredditTrendsType[] = [];
    comparedSubreddits.map((subreddit, i) => {
      if (subreddit && subreddit !== '') {
        const currentOffset = offset;
        getSubredditTrend({
          ...this.globalFilters,
          name: subreddit,
          period: this.period,
        })
          .then((json) => {
            if (json.subreddit_data) {
              const data = json.subreddit_data[0];
              tempTrends[i - currentOffset] = {
                keyword: data.subreddit,
                trend: this.prepareCompareTrend(data.trend, period),
                topPosts: data.top_link.map((item) => {
                  const splitedItem = item.split('->');
                  return {
                    topPosts: splitedItem[0],
                    clicks: +splitedItem[1],
                  };
                }),
              };
            } else {
              tempTrends[i - currentOffset] = {
                keyword: subreddit,
                trend: Array(
                  period === 'm1' ? 4 : period === 'm3' ? 12 : 24
                ).fill(0),
                topPosts: [],
              };
            }
          })
          .then(() => {
            this.comparedSubredditsTrends = tempTrends;
          })
          .finally(() => this.setLoading('subredditsCompared', false))
          .catch((err) => {
            console.log(err);
          });
      } else {
        offset += 1;
      }
    });
  };

  @action getPosts = () => {
    this.setLoading('posts', true);
    getTopPosts({...this.globalFilters})
      .then((json) => {
        if (json.post_data) {
          const data = json.post_data;
          this.posts = data.map((post) => ({
            ...post,
            trend: post.trend ? this.convertTrend(post.trend) : [],
            post: post.post_name,
          }));
        } else {
          this.posts = [];
        }
      })
      .finally(() => this.setLoading('posts', false))
      .catch((err) => {});
  };
}
export default new appStore();
