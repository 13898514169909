import { Box } from "@semcore/ui/flex-box";
import { SearchSection, Table } from "../../components";
import { appStore } from "../../stores";
import { useEffect } from "react";
import { observer } from "mobx-react";

const SubredditsTab = observer(() => {

    useEffect(()=>{
     appStore.getSubreddits();   
    },[appStore.globalFilters,appStore.isNew.subreddits])

    return(
        <Box mt={5} id='subreddits-overview'>
            <SearchSection
                inputPlaceholder="Type subbredit"
                type='subreddits'
            />
            <Table
                tableType='Subreddit'
                data={appStore.subreddits}
                dataTest='subreddits-table-overview'
            />
        </Box>
    )
})

export default SubredditsTab;