import React, {FC, useEffect} from 'react';
import {
  Plot,
  Line,
  XAxis,
  YAxis,
  HoverLine,
  Tooltip,
  minMax,
} from '@semcore/ui/d3-chart';
import {curveBumpX} from 'd3-shape';
import {Box, Flex} from '@semcore/ui/flex-box';
import {Text} from '@semcore/ui/typography';
import {scaleLinear, scaleTime} from 'd3-scale';
import Pills from '@semcore/ui/pills';
import {appStore} from '../../stores';
import {ComparedTrendsType} from '../../types/entity';
import moment from 'moment';
import {observer} from 'mobx-react';
import resolveColor from '@semcore/ui/utils/';
import {convertNumberToShortString, gaussRound} from '../../utils/helpers';
import {colors} from '../../types/enums';
import SpinContainer from '@semcore/ui/spin-container';

interface Props {
  width: number;
  data: ComparedTrendsType[];
  type: 'keywordsCompared' | 'subredditsCompared';
  dataTest?: string;
}

function formatDate(value: any, options: any) {
  return new Intl.DateTimeFormat('en', options).format(value);
}

const MainChart: FC<Props> = observer(({width, data, type,dataTest}) => {
  useEffect(() => {
    type === 'subredditsCompared'
      ? appStore.getComparedSubredditsTrends()
      : appStore.getComparedKeywordsTrends();
  }, [appStore.period]);

  const getLastFriday = (start: string) => {
    let date = moment(start, 'YYYYMM').add(1, 'month').add(-1, 'day');
    let day = date.weekday();
    while (day !== 5) {
      date = date.add(-1, 'day');
      day = date.weekday();
    }
    return date.add(1, 'weeks');
  };

  const {period, globalFilters} = appStore;
  const periodNumber = period == 'm1' ? 4 : period == 'm3' ? 12 : 24;
  const chartData: {[key: string]: any} = Array.from(
    Array(periodNumber).keys()
  ).map((_, i) => ({
    time: getLastFriday(globalFilters.month)
      .subtract(periodNumber - i, 'weeks')
      .toDate(),
    ...data
      .map((item) => {
        if (item && item.keyword)
          return {
            [item.keyword]:
              i < periodNumber - (item.trend?.length ?? 0)
                ? 0
                : item.trend?.[i - (periodNumber - (item.trend?.length ?? 0))],
          };
      })
      .reduce((combo, item) => {
        return {...combo, ...item};
      }, {}),
  }));
  const marginLeft = 60;
  const marginBottom = 26;
  const marginRight = 16;
  const height = 217;
  const xScale = scaleTime()
    .range([marginLeft, width - marginRight])
    .domain(minMax(chartData, 'time'));
  const yScale = scaleLinear()
    .range([height - marginBottom, marginBottom])
    .domain([
      0,
      Math.max(
        ...data.map((item) => (item ? Math.max(...(item.trend ?? [])) : 0))
      ),
    ]);
  return (
    <Box mt={5} data-test={dataTest}>
      <SpinContainer loading={appStore.loading[type]}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text tag={'h6'} fontSize={'14px'}>
            Interest over time
          </Text>
          <Pills
            size="m"
            value={appStore.period}
            onChange={appStore.setTrendPeriod}
          >
            <Pills.Item value={'m1'}>
              <Pills.Item.Text>Last Month</Pills.Item.Text>
            </Pills.Item>
            <Pills.Item value={'m3'}>Last 3 Months</Pills.Item>
            <Pills.Item value={'m6'}>Last 6 Months</Pills.Item>
          </Pills>
        </Flex>
        <Plot
          data={chartData}
          scale={[xScale, yScale]}
          width={width}
          height={height}
          mt={3}
        >
          <YAxis>
            <YAxis.Ticks>
              {({value, index}) => ({
                children:
                  index % 2 === 1 ? convertNumberToShortString(value) : '',
              })}
            </YAxis.Ticks>
            <YAxis.Grid />
          </YAxis>
          <XAxis>
            <XAxis.Ticks>
              {({value, index}) => ({
                children:
                  period !== 'm1' || index % 2 === 1
                    ? formatDate(value, {
                        month: 'short',
                        day: 'numeric',
                      })
                    : '',
              })}
            </XAxis.Ticks>
          </XAxis>
          <Tooltip tag={HoverLine} x="time" wMin={100}>
            {({xIndex}) => {
              return {
                children: (
                  <>
                    <Tooltip.Title>
                      {formatDate(chartData[xIndex as number].time, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </Tooltip.Title>

                    {data.map((item, index) => {
                      return (
                        <Flex justifyContent="space-between" mt={1}>
                          <Tooltip.Dot mr={4} color={colors[index]}>
                            {item.keyword}
                          </Tooltip.Dot>
                          <Flex justifyContent="space-betwwen">
                            <Text color="#6C6E79">
                              {gaussRound(
                                (chartData[xIndex as number][item.keyword] /
                                  ((
                                    Object.values({
                                      ...chartData[xIndex as number],
                                      time: 0,
                                    }) as number[]
                                  ).reduce((a, b) => a + b, 0) || 1)) *
                                  100,
                                0
                              ) + '%'}
                            </Text>
                            <Text bold ml={2}>
                              {chartData[xIndex as number][item.keyword]}
                            </Text>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </>
                ),
              };
            }}
          </Tooltip>
          {data.map((item, index) => {
            if (item && item.keyword)
              return (
                <Line
                  x="time"
                  y={item.keyword}
                  key={'main-chart' + item.keyword}
                  color={colors[index]}
                  curve={curveBumpX}
                >
                  <Line.Dots initialAnimation />
                </Line>
              );
          })}
        </Plot>
      </SpinContainer>
    </Box>
  );
});

export default MainChart;
