import {Box, Flex} from '@semcore/ui/flex-box';
import Button from '@semcore/ui/button';
import FileExportM from '@semcore/ui/icon/FileExport/m';
import {Text} from '@semcore/ui/typography';
import {appStore} from '../../stores';
import {useEffect, useRef, useState} from 'react';
import TabLine from '@semcore/ui/tab-line';
import {observer} from 'mobx-react';
import {SearchInput} from '../../components/SearchInput/SearchInput';
import MainChart from '../../components/MainChart/MainChart';
import {SmallTableSection, Table} from '../../components';
import Dot from '@semcore/ui/dot';
import {colors} from '../../types/enums';

const CompareTab = observer(() => {
  const boxRef = useRef<HTMLDivElement>(null);



  useEffect(() => {
    appStore.getComparedSubredditsTrends();
  }, [appStore.mainSubreddit,appStore.globalFilters]);

  return (
    <Box mt={5} ref={boxRef} data-test='comparison-overview'>
      <Flex justifyContent="space-between">
        <Text tag={'h4'} fontSize={'24px'}>
          {appStore.mainSubreddit !== ''
            ? appStore.mainSubreddit
            : 'Subreddit name'}
        </Text>
        {/* <Box>
          <Button>
            <Flex alignItems="center">
              <FileExportM mr={2} /> Export to PDF
            </Flex>
          </Button>
        </Box> */}
      </Flex>
      <Box mt={3}>
        <SearchInput
          handleSearchButtonPress={appStore.getComparedSubredditsTrends}
          handleSearchInputAction={(value: string, index?: number) => {
            appStore.setComparedSubreddits(
              appStore.comparedSubreddits.map((prevValue, ind) =>
                ind === index ? value : prevValue
              )
            );
          }}
          placeholder={'Subreddit name'}
          buttonText="Compare"
          currentSearchValues={appStore.comparedSubreddits}
          dropdown={'subreddits'}
          // disabledInput={0}
        />
      </Box>
      <MainChart
        width={boxRef.current?.offsetWidth as number}
        data={appStore.comparedSubredditsTrends}
        type='subredditsCompared'
        dataTest='comparison-chart-overview'
      />
      <TabLine
        size="m"
        underlined={false}
        mt={3}
        onChange={() =>  {}}
        value={'topPosts'}
      >
        <TabLine.Item value={'topPosts'}>Top Posts</TabLine.Item>
      </TabLine>
      <SmallTableSection
        type={'topPosts'}
        data={appStore.comparedSubredditsTrends}
      />
    </Box>
  );
});

export default CompareTab;
