import React, {Component} from 'react';

interface IPage {
  style?: any;
  children: any;
  dataTest?: string;
}

export default class Page extends Component<IPage> {
  render() {
    const {children, style} = this.props;
    return (
      <div
        style={{
          ...style,
          // height: '95vh',
          height: '100%',
          minWidth: 1138,
          // maxWidth: 1138,
          marginLeft: '30px',
          marginRight: '20px',
          // paddingTop: 24,
          // paddingBottom: 24,
          // paddingBottom: '5vh',
        }}
        data-test={this.props.dataTest}
      >
        {children}
      </div>
    );
  }
}
