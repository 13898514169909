import TabLine from '@semcore/ui/tab-line';
import SelectData from '../SelectData/SelectData';
import {TabType, appStore} from '../../stores';
import {Box, Flex} from '@semcore/ui/flex-box';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import {AvailableCountriesList, FullCountriesNames} from '../../types/enums';
import {GetCountries, GetCountriesType} from '../../api/request';

const handleTabChange = (value: string) => {
  appStore.setSelectedTab(value as TabType);
};

const MainHeader = observer(() => {
  const [locations, setLocations] = useState<GetCountriesType[]>([]);

  useEffect(() => {
    GetCountries()
      .then((data) => {
        if (data && data.countries) {
          let countries = data.countries;
          const usa = countries.splice(
            countries.findIndex((country) => country.iso_name === 'USA'),
            1
          )[0];
          countries.splice(0, 0, usa);
          setLocations([
            {iso_name: 'all', full_name: 'All regions'},
            ...data.countries,
          ]);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const getMonthlyDates = () => {
    const startDate = moment().subtract(1,'year');
    const currentDate = moment();

    const dates = [];

    let date = startDate.clone();
    while (date.isBefore(currentDate, 'month')) {
      dates.push({
        value: date.format('YYYYMM'),
        label: date.format('MMMM YYYY'),
        children: date.format('MMMM YYYY'),
      });
      date.add(1, 'month');
    }

    if (+moment().format('D') >= 5) {
      dates.push({
        value: date.format('YYYYMM'),
        label: date.format('MMMM YYYY'),
        children: date.format('MMMM YYYY'),
      });
    }

    return dates.reverse();
  };

  const monthFilterOptions = getMonthlyDates();
  const allFilterOption = {
    value: 'all',
    label: 'All',
    children: 'All',
  };
  const devicesFilterOptions = [
    allFilterOption,
    {
      value: 'desktop',
      label: 'Desktop',
      children: 'Desktop',
    },
    {
      value: 'mobile',
      label: 'Mobile',
      children: 'Mobile',
    },
  ];
  const genderFilterOptions = [
    allFilterOption,
    {
      value: 'M',
      label: 'Male',
      children: 'Male',
    },
    {
      value: 'F',
      label: 'Female',
      children: 'Female',
    },
  ];
  const ageFilterOptions = [
    '<18',
    '18-24',
    '25-34',
    '35-44',
    '45-54',
    '55-64',
    '65+',
  ].map((value) => ({
    value: value,
    label: value,
    children: value,
  }));

  return (
    <Box pt={5}>
      <Flex justifyContent="end">
        <Box>
          {'Time period: '}
          <SelectData
            options={monthFilterOptions}
            value={appStore.globalFilters.month ?? moment().format('YYYYMM')}
            onChange={(value) =>
              appStore.setGlobalFilters({
                ...appStore.globalFilters,
                month: value as string,
              })
            }
            tag="link"
            multiselect={false}
            disable={0}
            disableTooltipText={`Data for the ${moment(
              monthFilterOptions[0].value,
              'YYYYMM'
            ).format('MMMM')} will be available on the 5th of ${moment(
              monthFilterOptions[0].value,
              'YYYYMM'
            )
              .add(1, 'month')
              .format('MMMM')}`}
          />
        </Box>
        <Box ml={4}>
          {'Devices: '}
          <SelectData
            options={devicesFilterOptions}
            value={appStore.globalFilters.devices}
            onChange={(value) =>
              appStore.setGlobalFilters({
                ...appStore.globalFilters,
                devices: value as string,
              })
            }
            tag="link"
          />
        </Box>
        <Box ml={4}>
          {'Gender: '}
          <SelectData
            options={genderFilterOptions}
            value={appStore.globalFilters.gender}
            onChange={(value) =>
              appStore.setGlobalFilters({
                ...appStore.globalFilters,
                gender: value as string,
              })
            }
            tag="link"
          />
        </Box>
        {/* <Box ml={4}>
          {'Age: '}
          <SelectData
            options={ageFilterOptions}
            value={appStore.globalFilters.age}
            onChange={(value) => {
              appStore.setGlobalFilters({
                ...appStore.globalFilters,
                age: value as string[],
              });
            }}
            tag="link"
            placeholer="0 selected"
            multiselect={true}
          />
        </Box> */}
        <Box ml={4}>
          {'Location: '}
          <SelectData
            options={locations.map((location) => {
              return {
                value: location.iso_name,
                label: location.full_name,
                children: location.full_name,
              };
            })}
            value={appStore.globalFilters.location}
            onChange={(value) => {
              appStore.setGlobalFilters({
                ...appStore.globalFilters,
                location: value as string,
              });
            }}
            tag="link"
          />
        </Box>
      </Flex>

      <TabLine
        size={'l'}
        onChange={handleTabChange}
        value={appStore.selectedTab}
      >
        <TabLine.Item value="subreddits" data-test={'tab-subreddits'}>Top subreddits</TabLine.Item>
        <TabLine.Item value="compare" data-test={'tab-comparison'}>Subreddits comparison</TabLine.Item>
        <TabLine.Item value="posts" data-test={'tab-posts'}>Posts</TabLine.Item>
        <TabLine.Item value="keywords" data-test={'tab-keywords'}>Keywords</TabLine.Item>
      </TabLine>
    </Box>
  );
});

export default MainHeader;
