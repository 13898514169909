import qs from 'qs';
import {ExportToCsv} from 'export-to-csv';
import moment from 'moment';
import store from '../stores/store';
import { useNavigate } from 'react-router-dom';

export enum StatusColors {
  green = '#27AE60',
  yellow = '#F2C94C',
  orange = '#F2994A',
  red = '#EB5757',
}

//парсит строку гет параметров в объект
export const getQuery = (query: string) => {
  return qs.parse(query, {ignoreQueryPrefix: true});
};

//собирает строку из объекта параметров
export const setQuery = (params: {[key: string]: any}, noPrefix?: boolean) => {
  return qs.stringify(params, {addQueryPrefix: !noPrefix});
};

export const EventEmitter = {
  events: {} as any,
  execute: function (event: string, data: any) {
    if (!this.events[event]) return;
    this.events[event].forEach((callback: any) => callback(data));
  },
  subscribe: function (event: string, callback: any) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  delete: function (event: string) {
    delete this.events[event];
  },
};

export const csvExporter = (options: any, data: any) => {
  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(data);
};

export const roundThousand = (value: any) => {
  if (value >= 1000000) {
    return gaussRound(+value / 1000000, 1) + 'M';
  } else if (value >= 1000) {
    return gaussRound(+value / 1000, 1) + 'K';
  } else {
    return value;
  }
};

//округление по гаусу
export const gaussRound = (num: number, decimalPlaces: number) => {
  let d = decimalPlaces || 0,
    m = Math.pow(10, d),
    n = +(d ? num * m : num).toFixed(8),
    i = Math.floor(n),
    f = n - i,
    e = 1e-8,
    r = f > 0.5 - e && f < 0.5 + e ? (i % 2 == 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
};

export const convertNumberToShortString = (number: number) => {
  const abbreviations = {
    T: 1000000000000,
    B: 1000000000,
    M: 1000000,
    K: 1000,
  };

  let abbreviation: keyof typeof abbreviations;
  for (abbreviation in abbreviations) {
    if (number >= abbreviations[abbreviation]) {
      const convertedNum = number / abbreviations[abbreviation];
      return `${convertedNum.toFixed(
        Number.isInteger(convertedNum) ? 0 : 1
      )}${abbreviation}`;
    }
  }

  return number.toString();
};


/**
 * return current size of items in localStorage (for caching stuff)
 * @returns
 */
export const getLocalStorageSize = () => {
  let _lsTotal = 0,
    _xLen,
    _x;
  try {
    for (_x in localStorage) {
      if (!localStorage?.hasOwnProperty(_x)) {
        continue;
      }
      _xLen = (localStorage?.[_x]?.length + _x.length) * 2;
      _lsTotal += _xLen;
    }
  } catch (err) {
    console.log(err);
  }
  return (_lsTotal / 1024).toFixed(2);
};


export const throttle = (func: () => void, ms: number) => {
  let isThrottled = false;
  let savedArgs: any;
  let savedThis: any;

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments;
      //@ts-ignore
      savedThis = this;
      return;
    }
    //@ts-ignore
    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function () {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
};


export const encodeUrl = (url:string) => {
  const result = url.split('').map((char)=>{
    switch(char){
      case '!': return '%21';
      case '#': return '%23';
      case '$': return '%24';
      case '%': return '%25';
      case '&': return '%26';
      case "'": return '%27';
      case '(': return '%28';
      case ')': return '%29';
      case '*': return '%2A';
      case '+': return '%2B';
      case ',': return '%2C';
      case '/': return '%2F';
      case ':': return '%3A';
      case ';': return '%3B';
      case '=': return '%3D';
      case '?': return '%3F';
      case '@': return '%40';
      case '[': return '%5B';
      case ']': return '%5D'; 
      case '-': return '%2D';
      case '_': return '%5F';
      case '.': return '%2E';
      case '~': return '%7E';
      default: return encodeURIComponent(char);
    }
  }).join('');
  return result;
}
