export enum AvailableCountriesList {
  all = 'all',
  USA = 'USA',
  ARG = 'ARG',
  AUS = 'AUS',
  BRA = 'BRA',
  CAN = 'CAN',
  COL = 'COL',
  DEU = 'DEU',
  ESP = 'ESP',
  FRA = 'FRA',
  GBR = 'GBR',
  IND = 'IND',
  ITA = 'ITA',
  MEX = 'MEX',
  PHL = 'PHL',
  POL = 'POL',
  // RUS = 'RUS',
  TUR = 'TUR',
  UKR = 'UKR',
}

export enum FullCountriesNames {
  all = 'All Regions',
  USA = 'United States',
  ARG = 'Argentina',
  AUS = 'Australia',
  BRA = 'Brazil',
  CAN = 'Canada',
  COL = 'Colombia',
  DEU = 'Germany',
  ESP = 'Spain',
  FRA = 'France',
  GBR = 'United Kingdom',
  IND = 'India',
  ITA = 'Italy',
  MEX = 'Mexico',
  PHL = 'Philippines',
  POL = 'Poland',
  // RUS = 'Russia',
  TUR = 'Turkey',
  UKR = 'Ukraine',
}

export const colors = ['#008ff8','#009f81','#ff642d','#ab6cfe']