import React, {Component, useEffect, useState} from 'react';
import {Page} from '../../ui';
import {useLocation, useNavigate} from 'react-router-dom';
import store from '../../stores/store';
import {MainHeader} from '../../components';
import {observer} from 'mobx-react';
import {appStore} from '../../stores';
import {KeywordsTab, SubredditsTab, PostsTab, CompareTab} from '../tabs';

const MainPage = observer(() => {
  const historyTab = useLocation();
  const [activeTab, setActiveTab] = useState<number>(1);
  const navigation = useNavigate();

  useEffect(() => {
    document.body.style.background = 'rgb(244, 245, 249)';
    let lastSavedTab: string | null = '';

    try {
      lastSavedTab = localStorage?.getItem('savedLastTab');
    } catch (err) {
      console.log(err);
    }

    if (lastSavedTab) {
      setActiveTab(+lastSavedTab);
    }
    // history has lower priority than saved tab from LocalStorage
    else if (historyTab?.state?.chosenTab) {
      changeTab(historyTab?.state?.chosenTab);
    }
  }, []);

  const changeTab = (tab: number) => {
    setActiveTab(tab);
    try {
      localStorage?.setItem('savedLastTab', String(tab));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    appStore.loadError.error && navigation('/error', {replace: true});
  },[appStore.loadError.error])

  return (
    <Page
      style={{
        background: '#F4F5F9',
      }}
      dataTest={'overview'}
    >
      <div
        style={{
          paddingBottom: '30px',
          height: 'auto',
        }}
      >
        <MainHeader />
        {appStore.selectedTab === 'keywords' && <KeywordsTab />}
        {appStore.selectedTab === 'subreddits' && <SubredditsTab />}
        {appStore.selectedTab === 'posts' && <PostsTab />}
        {appStore.selectedTab === 'compare' && <CompareTab />}
      </div>
    </Page>
  );
  // }
});

export default MainPage;
