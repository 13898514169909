import React, {useEffect} from 'react';
import {Plot, XAxis, YAxis, minMax, Area} from '@semcore/ui/d3-chart';
import {scaleLinear} from 'd3-scale';
import {curveCardinal} from 'd3-shape';
import moment from 'moment';
import { observer } from 'mobx-react';
import { appStore } from '../../stores';
import { Box } from '@semcore/ui/flex-box';
import { SearchSection, Table } from '../../components';
function formatDate(value: any, options: any) {
  return new Intl.DateTimeFormat('en', options).format(value);
}
const PostsTab = observer(() => {
  useEffect(()=>{
    appStore.getPosts();   
   },[appStore.globalFilters])

   return(
       <Box mt={5} data-test='posts-overview'>
           <Table
               tableType='Post'
               data={appStore.posts}
               dataTest='posts-table-overview'
           />
       </Box>
   )
});

export default PostsTab;
