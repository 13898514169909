import Button from '@semcore/ui/button';
import {Box, Flex} from '@semcore/ui/flex-box';
import Input from '@semcore/ui/input';
import Select from '@semcore/ui/select';
import React, {useEffect} from 'react';
import {ButtonTrigger} from '@semcore/ui/base-trigger';
import DropdownMenu from '@semcore/ui/dropdown-menu';
import {observer} from 'mobx-react';
import { DropdownSearch } from '..';

interface Props {
  handleSearchInputAction: (value: string, index?: number) => void;
  currentSearchValues: string[];
  handleSearchButtonPress: () => void;
  placeholder?: string;
  buttonText: string;
  disabledInput?: number;
  dropdown?: 'keywords' | 'subreddits'
  children?: React.ReactNode;
}

export const SearchInput: React.FC<Props> = observer(
  ({
    handleSearchButtonPress,
    handleSearchInputAction,
    currentSearchValues,
    placeholder,
    buttonText,
    disabledInput,
    dropdown,
    children,
  }) => {

    // useEffect(() => {
    //   const keyDownHandler = (event:KeyboardEvent) => {
    //     if (event.key === 'Enter') {
    //       event.preventDefault();
    //       handleSearchButtonPress();
    //     }
    //   };
  
    //   document.addEventListener('keydown', keyDownHandler);
  
    //   return () => {
    //     document.removeEventListener('keydown', keyDownHandler);
    //   };
    // }, []);


    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
          event.preventDefault();
          handleSearchButtonPress();
      }
    }

    return (
      <Box>
        <Flex>
          {currentSearchValues.map((value, index) =>
            dropdown && index !== disabledInput ? (
              <DropdownSearch 
                type={dropdown}
                search={value}
                onChange = {(newString: string) => {
                  handleSearchInputAction(newString, index)
                }}
                keyNumber={index}
                placeholder={placeholder}
                keyDownHandler={keyDownHandler}
              />
            ) : (
              <Select
                state={'normal'}
                interaction="focus"
                value={value}
                size="l"
                key={`select-input-${placeholder}-${index}`}
              >
                <Select.Trigger
                  size={'m'}
                  w={'196px'}
                  m={'0 8px 0 0'}
                  tag={Input}
                >
                  <Select.Trigger.Text wMin={'100%'}>
                    <Input.Value
                      wMin={'100%'}
                      onChange={(newString) =>
                        currentSearchValues.length > 0
                          ? handleSearchInputAction(newString, index)
                          : handleSearchInputAction(newString)
                      }
                      value={value}
                      placeholder={placeholder ?? 'Search'}
                      size="m"
                      disabled={index === disabledInput}
                      onKeyDown={keyDownHandler}
                    />
                  </Select.Trigger.Text>
                </Select.Trigger>
              </Select>
            )
          )}
          <Button
            onClick={handleSearchButtonPress}
            use="primary"
            theme="success"
            size="m"
          >
            <Button.Text>{buttonText}</Button.Text>
          </Button>
          {children}
        </Flex>
      </Box>
    );
  }
);
