import moment from 'moment';
import {AvailableCountriesList} from '../types/enums';
import {encodeUrl, setQuery} from '../utils/helpers';
import Api from './network';
import {TrendPeriodType} from '../types/entity';

export const api = new Api();

export type GlobalFiltersParams = {
  month: string;
  devices: string;
  gender: string;
  age: string[];
  location: string;
};

export type VisitsType = {
  num_visits: number;
  num_unique_users: number;
  events_per_visitor: number;
  trend?: number[];
  change: number;
};

export type GetTopParams = {
  isNew: number;
} & GlobalFiltersParams;

export type GetParams = {
  name: string;
  isNew: number;
} & GlobalFiltersParams;

export type GetTrendParams = {
  period: TrendPeriodType;
  name: string;
} & GlobalFiltersParams;

export type GetSubrreditsType = {
  subreddit: string;
  subreddit_link: string;
  device: string;
  age: string;
  gender: string;
  new: number;
} & VisitsType;

export type GetPostsType = {
  post_name: string;
  post_id: string;
  post_url: string;
  device: string;
  age: string;
  gender: string;
  // new: number;
} & VisitsType;

export type GetKeywordsType = {
  keyword: string;
  new: number;
  search_volume: number;
} & VisitsType;

export type GetTrendType = {
  keyword: string;
  trend: number[];
};

export type GetSubredditTrendType = {
  subreddit: string;
  trend: number[];
};

export type GetCountriesType = {
  iso_name: string,
  full_name: string,
}

export type GetTopKeywordsResponse = {
  keyword_data: [GetKeywordsType];
};


export type GetKeywordsTrendsResponse = {
  keyword_data: [{top_ads: string[]; top_link: string[]} & GetTrendType];
};

export type GetSubredditTrendsResponse = {
  subreddit_data: [{top_link: string[]} & GetSubredditTrendType];
};

export type GetTopSubredditsResponse = {
  subreddit_data: [GetSubrreditsType];
};

export type GetTopPostsResponse = {
  post_data: [GetPostsType];
};

export type GetCountriesResponse = {
  countries: [GetCountriesType]
}

export const getTopSubreddits = async (filters: GetTopParams) => {
  return (await api.get({
    route: `subreddit/top/?month=${filters.month}&country=${
      filters.location
    }&device=${filters.devices}&gender=${filters.gender}&is_new=${
      filters.isNew
    }&age=${filters.age.toString()}`,
  })) as GetTopSubredditsResponse;
};

export const getSubredditsByName = async (filters: GetParams) => {
  return (await api.get({
    route: `subreddit/search/${encodeUrl(filters.name)}?month=${filters.month}&country=${
      filters.location
    }&device=${filters.devices}&gender=${filters.gender}&is_new=${
      filters.isNew
    }&age=${filters.age.toString()}`,
  })) as GetTopSubredditsResponse;
};

export const getTopKeywords = async (filters: GetTopParams) => {
  return (await api.get({
    route: `keyword/top/?month=${filters.month}&country=${
      filters.location
    }&device=${filters.devices}&gender=${filters.gender}&is_new=${
      filters.isNew
    }&age=${filters.age.toString()}`,
  })) as GetTopKeywordsResponse;
};

export const getKeywordsByName = async (filters: GetParams) => {
  return (await api.get({
    route: `keyword/search/${encodeUrl(filters.name)}?month=${filters.month}&country=${
      filters.location
    }&device=${filters.devices}&gender=${filters.gender}&is_new=${
      filters.isNew
    }&age=${filters.age.toString()}`,
  })) as GetTopKeywordsResponse;
};

export const getKeywordTrend = async (filters: GetTrendParams) => {
  return (await api.get({
    route: `keyword/interest/${encodeUrl(filters.name)}?period=${
      filters.period
    }&month=${filters.month}&country=${filters.location}&device=${
      filters.devices
    }&gender=${filters.gender}&age=${filters.age.toString()}`,
  })) as GetKeywordsTrendsResponse;
};

export const getSubredditTrend = async (filters: GetTrendParams) => {
  return (await api.get({
    route: `subreddit/interest/${encodeUrl(filters.name)}?period=${filters.period}&month=${
      filters.month
    }&country=${filters.location}&device=${filters.devices}&gender=${
      filters.gender
    }&age=${filters.age.toString()}`,
  })) as GetSubredditTrendsResponse;
};

export const getTopPosts = async (filters: GlobalFiltersParams) => {
  return (await api.get({
    route: `post/top/?month=${filters.month}&country=${
      filters.location
    }&device=${filters.devices}&gender=${filters.gender}&age=${filters.age.toString()}`,
  })) as GetTopPostsResponse;
};

export const GetCountries = async () => {
  return (await api.get({
    route: `countries/`
  })) as GetCountriesResponse
}