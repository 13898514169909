import {appStore} from '../stores';
import {RedirectToErrorPage} from '../utils/helpers';
import url from './url';

class Api {
  token = undefined;

  setError = (tokenError, errorText) => {
    console.log(errorText);
    appStore.setLoadError({
      error: true,
      tokenError: appStore.loadError.tokenError || tokenError,
    });
  };

  get = ({...params}) => this.request({...params, method: 'GET'});
  post = ({...params}) => this.request({...params, method: 'POST'});
  put = ({...params}) => this.request({...params, method: 'PUT'});
  delete = ({...params}) => this.request({...params, method: 'DELETE'});

  getToken = () => {
    return new Promise((resolve, reject) => {
      window.SM.client('getAccessToken')
        .then((json) => {
          this.token = json;
          resolve();
        })
        .catch((error) => {
          this.setError(true, error);
          reject();
        });
    });
  };

  request = async ({route = '', method, headers = null, body}) => {
    let params = {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.token}`,
        ...headers,
      },
    };

    if (method != 'GET') params.body = JSON.stringify(body);

    return new Promise((resolve, reject) => {
      fetch(`${url}${route}`, params)
        .then((response) => {
          const status = response.status;
          const statusText = response.statusText;
          if (status == 200) {
            return response
              .text()
              .then((text) => {
                resolve(JSON.parse(text));
              })
              .catch((error) => {
                throw error;
              });
          } else if (status == 401 || status == 403) {
            return this.getToken()
              .then(() => {
                return this.request({route, method, headers, body})
                  .then((json) => {
                    resolve(json);
                  })
                  .catch((error) => {
                    throw error;
                  });
              })
              .catch((error) => {
                throw error;
              });
          } else {
            throw new Error(`Error: ${statusText}, status: ${status}`);
          }
        })
        .catch((error) => {
          this.setError(false, error);
        });
    });
  };
}

export default Api;
