import {Box, Flex} from '@semcore/ui/flex-box';
import Button from '@semcore/ui/button';
import FileExportM from '@semcore/ui/icon/FileExport/m';
import {Text} from '@semcore/ui/typography';
import {SearchInput} from '../SearchInput/SearchInput';
import {appStore} from '../../stores';
import MainChart from '../MainChart/MainChart';
import {useEffect, useRef, useState} from 'react';
import TabLine from '@semcore/ui/tab-line';
import {observer} from 'mobx-react';
import ArrowRightM from '@semcore/ui/icon/ArrowRight/m';
import Link from '@semcore/ui/link';
import Table from '../Table/Table';
import Dot from '@semcore/ui/dot';
import {colors} from '../../types/enums';
import SmallTableSection from '../SmallTableSection/SmallTableSection';

const KeywordSection = observer(() => {
  const [tab, setTab] = useState<'topClicks' >('topClicks');
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    appStore.getComparedKeywordsTrends();
  },[appStore.mainKeyword])

  return (
    <Box mt={5} ref={boxRef}>
      <Flex justifyContent="space-between">
        <Text tag={'h4'} fontSize={'24px'}>
          {appStore.comparedKeywords[0]!=='' ? appStore.comparedKeywords[0] : 'Keyword name'}
        </Text>
        {/* <Box>
          <Button>
            <Flex alignItems="center">
              <FileExportM mr={2} /> Export to PDF
            </Flex>
          </Button>
        </Box> */}
      </Flex>
      <Box mt={3}>
        <SearchInput
          dropdown={'keywords'}
          handleSearchButtonPress={appStore.getComparedKeywordsTrends}
          handleSearchInputAction={(value: string, index?: number) => {
            appStore.setComparedKeywords(
              appStore.comparedKeywords.map((prevValue, ind) =>
                ind === index ? value : prevValue
              )
            );
          }}
          placeholder={'Keyword name'}
          buttonText="Compare"
          currentSearchValues={appStore.comparedKeywords}
          disabledInput={0}
        />
      </Box>
      <MainChart
        width={boxRef.current?.offsetWidth as number}
        data={appStore.comparedKeywordsTrends}
        type='keywordsCompared'
        dataTest='keywords-chart-overview'
      />
      <TabLine size="m" underlined={false} mt={3} onChange={setTab} value={tab}>
        <TabLine.Item value={'topClicks'}>Top Clicks</TabLine.Item>
        {/* <TabLine.Item value={'topAds'}>Top Ads</TabLine.Item> */}
      </TabLine>
      <SmallTableSection type={tab} data={appStore.comparedKeywordsTrends}/>
    </Box>
  );
});

export default KeywordSection;
