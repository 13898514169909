import {FC, useEffect, useState} from 'react';
import {SearchInput} from '../SearchInput/SearchInput';
import Switch from '@semcore/ui/switch';
import InfoM from '@semcore/ui/icon/Info/m';
import {appStore} from '../../stores';
import {Box, Flex} from '@semcore/ui/flex-box';
import Link from '@semcore/ui/link';
import {observer} from 'mobx-react';
import ArrowRightM from '@semcore/ui/icon/ArrowRight/m';
import Tooltip from '@semcore/ui/tooltip';
import {Text} from '@semcore/ui/typography';
import {SearchStringType} from '../../types/entity';

interface ISearchSection {
  inputPlaceholder?: string;
  disableSearch?: boolean;
  type?: keyof SearchStringType;
}

const SearchSection: FC<ISearchSection> = observer(
  ({inputPlaceholder, disableSearch, type = 'subreddits'}) => {
    const [isNew, setIsNew] = useState<number>(appStore.isNew.keywords);

    useEffect(() => {
      if (appStore.selectedTab !== 'compare') {
        setIsNew(appStore.isNew[appStore.selectedTab]);
      }
    }, [appStore.selectedTab]);

    const handleSearch = (search: string) => {
      appStore.setSearchString(search, type);
    };

    const handleSwitchChange = (value: boolean) => {
      setIsNew(value ? 1 : 0);
      appStore.setIsNew(value ? 1 : 0);
    };

    return (
      <Flex justifyContent={disableSearch ? 'right' : 'space-between'}>
        {!disableSearch && (
          <SearchInput
            dropdown={type}
            handleSearchButtonPress={() => {
              appStore.selectedTab === 'subreddits'
                ? appStore.getSubreddits()
                : appStore.selectedTab === 'keywords'
                ? appStore.getKeywords()
                : console.log('buttonSearch');
            }}
            handleSearchInputAction={handleSearch}
            placeholder={inputPlaceholder}
            buttonText="Search"
            currentSearchValues={[appStore.searchString[type]]}
            children={
              appStore.selectedTab === 'subreddits' ? (
                <Flex alignItems="center">
                  <Link
                    ml={4}
                    size={200}
                    onClick={() => {
                      appStore.setSelectedTab('compare');
                    }}
                  >
                    <Link.Text>Compare</Link.Text>
                    <Link.Addon>
                      <ArrowRightM />
                    </Link.Addon>
                  </Link>
                </Flex>
              ) : (
                ''
              )
            }
          />
        )}
        <Box>
          <Switch size="l">
            <Switch.Addon>
              <Flex alignItems="center">
                <Text>Show only new</Text>
                <Tooltip title="Display only those keywords that have appeared in the current month.">
                  <div style={{cursor: 'pointer'}}>
                    <Flex alignItems="center">
                      <InfoM color="#A9ABB6" />
                    </Flex>
                  </div>
                </Tooltip>
              </Flex>
            </Switch.Addon>
            <Switch.Value
              checked={isNew === 0 ? false : true}
              onChange={handleSwitchChange}
            />
          </Switch>
        </Box>
      </Flex>
    );
  }
);

export default SearchSection;
