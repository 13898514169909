import React, {Component} from 'react';
import {Routes, Route} from 'react-router-dom';
import './assets/css/App.css';
import {MainPage, LoadingPage, EmptyPage} from './pages';
import {ErrorPage} from './pages/ErrorPage/ErrorPage';
import store from './stores/store';
import { ProtectedRoute } from './routes';

export enum SMClientActions {
  getAccessToken = 'getAccessToken',
  resizeWindow = 'resizeWindow',
  scrollTo = 'scrollTo',
  getPageInfo = 'getPageInfo',
  requestMainProductPurchase = 'requestMainProductPurchase',
  requestInAppPurchase = 'requestInAppPurchase',
  pushUrl = 'pushUrl',
  replaceUrl = 'replaceUrl',
  requestEmailSubscriptionChange = 'requestEmailSubscriptionChange',
  requestUserFeedback = 'requestUserFeedback',
}

export enum SMCallbackTypes {
  onUrlPop = 'onUrlPop',
  onScroll = 'onScroll',
}

declare global {
  interface Window {
    SM: {
      init: () => Promise<void>;
      client: (
        actionType: keyof typeof SMClientActions,
        ...props: any
      ) => Promise<any>;
      /**
       * callback that returns unsubscribe function
       */
      addCallback: (
        type: keyof typeof SMCallbackTypes,
        callback: (...props: any) => void
      ) => () => void;
    };
  }
}

class App extends Component {
  // componentDidMount() {

  //   console.log = () => {};
  //   store.restoreSavedTabs();
  // }
  render() {
    return (
      <Routes>
        <Route
          path="/main"
          element={
            // <ProtectedRoute>
              <MainPage />
            // </ProtectedRoute>
          }
        ></Route>
        <Route path="/error" element={<ErrorPage />}></Route>

        <Route path="/" element={<LoadingPage />}></Route>
        <Route path="*" element={<EmptyPage />}></Route>
      </Routes>
    );
  }
}

export default App;
