import React, {useEffect, useRef} from 'react';
import Spin from '@semcore/ui/spin';
import {Page} from '../../ui';
import Script from 'react-load-script';
import Api from '../../api/network';
import {useNavigate, useNavigation} from 'react-router-dom';
import {api, getExampleVideoKeywords} from '../../api/request';
import {appStore} from '../../stores';

const LoadingPage = () => {
  const navigation = useNavigate();
  const oldHeight = useRef(0);
  let attempts = 0;

  const redirectToMainPage = () => {
    navigation('/main', {replace: true});
  };

  const redirectToErrorPage = () => {
    navigation('/error', {replace: true});
  };

  useEffect(() => {
    if (appStore.loadError.error) redirectToErrorPage();
  }, [appStore.loadError.error]);

  const getToken = () => {
    api
      .getToken()
      .then(() => {
        redirectToMainPage();
      })
      .catch((err) => {
        if (attempts > 3) {
          console.log(err);
          appStore.setLoadError({
            error: true,
            tokenError: true,
          });
        } else {
          attempts += 1;
          setTimeout(() => {
            // reattempting to get token
            getToken();
          }, 1000);
        }
      });
  };

  const onLoad = () => {
    window.SM.init()
      .then(() => {
        getToken();
      })
      .then(() => {
        setInterval(() => {
          api.getToken();
          console.log('token refresh');
        }, 1000 * 60 * 5);
      })
      .catch((error) => {
        console.log(error);
        redirectToErrorPage();
      });
  };

  const onError = () => {};
  const onCreate = () => {};

  // render() {
  return (
    <Page style={{display: 'flex', justifyContent: 'center'}}>
      <Script
        url="https://static.semrush.com/app-center/sdk.js"
        onCreate={onCreate}
        onError={onError}
        onLoad={onLoad}
      />
      <Spin size={'xxl'} center />
    </Page>
  );
  // }
};

export default LoadingPage;
