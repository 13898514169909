import Dot from '@semcore/ui/dot';
import { colors } from '../../types/enums';
import { observer } from 'mobx-react';
import { appStore } from '../../stores';
import { Box, Flex } from '@semcore/ui/flex-box';
import { FC, useEffect } from 'react';
import Table from '../Table/Table';
import { Text } from '@semcore/ui/typography';
import { ComparedKeywordsTrendsType, ComparedSubredditTrendsType } from '../../types/entity';

interface Props{
    type: 'topClicks' | 'topPosts',
    data: any[],
};

const SmallTableSection:FC<Props> = observer(({type,data}) => {
  return (
      <Flex justifyContent="space-between">
        {data.map((item, index) => item && (
          <Box
            w={`calc(${100 / data.length}% - 6px)`}
            key={`keywords-${type}-${index}`}
          >
            <Table
              tableType={type}
              data={item[type]}
              disablePagination
              title={
                <Flex alignItems="center">
                  <Dot
                    initialAnimation={false}
                    size={'l'}
                    style={{backgroundColor: colors[index]}}
                  />
                  <Text size={400} ml={2} pb={1}>
                    {item.keyword}
                  </Text>
                </Flex>
              }
            />
          </Box>
        ))}
      </Flex>
  );
});

export default SmallTableSection;