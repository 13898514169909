import {Box, Flex} from '@semcore/ui/flex-box';
import Divider from '@semcore/ui/divider';
import {KeywordSection, SearchSection, Table} from '../../components';
import {appStore} from '../../stores';
import {useEffect} from 'react';
import {observer} from 'mobx-react';

const KeywordsTab = observer(() => {
  useEffect(() => {
    appStore.getKeywords();
  }, [appStore.globalFilters, appStore.isNew.keywords]);

  useEffect(() => {
    appStore.getComparedKeywordsTrends();
  },[appStore.mainKeyword])

  const boxStyle = {width: '50%', boxSizing: 'border-box'};

  return (
    <Box data-test='keywords-overview'>
      <Flex>
        <Box style={boxStyle} pr={5} mt={5}>
          <SearchSection 
            inputPlaceholder="Type keyword" 
            type='keywords'
          />
          <Table
            tableType="Keyword"
            data={appStore.keywords}
            rowClickHandle={(row: string) => {
              appStore.setMainKeyword(row);
            }}
            dataTest='keywords-table-overview'
          />
        </Box>
        <Divider orientation="vertical" />
        <Box style={boxStyle} pl={5}>
          <KeywordSection />
        </Box>
      </Flex>
    </Box>
  );
});

export default KeywordsTab;
